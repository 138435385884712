import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';
import { Checkbox } from '../../Checkbox/Checkbox';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * A hook to create a table {@link Column} object with multiple
 * selection checkboxes.
 *
 * @param accessor The key of a table's column.
 * @param width A string representing the width of the column.
 * @param dataSet An array of options; each option is tied to a checkbox.
 * @param stateUpdater A {@link SetStateAction} that logs the latest checkbox
 * selection.
 * @param HeaderBoxLabel An optional component to render a checkbox label.
 * @param resetColumnState An optional boolean to force column state back to its
 * initial 'no selections made' state . It can be useful when a table action occurs,
 * such as when a page is changed or a filter is added.
 */
export function useCheckboxColumn<T1 extends object, T2 extends object>(
  accessor: keyof T1,
  width: string,
  dataSet: T2[],
  stateUpdater: Dispatch<SetStateAction<T2[]>>,
  HeaderBoxLabel?: React.FunctionComponent,
  resetColumnState = false,
): Column<T1> {
  const [checkedState, setCheckedState] = useState<T2[]>([]);

  // Reset all checkboxes to 'unchecked'.
  useEffect(() => {
    if (resetColumnState) setCheckedState([]);
  }, [resetColumnState]);

  // Update parent of checked state.
  useEffect(() => {
    stateUpdater(checkedState);
  }, [checkedState, stateUpdater]);

  const SelectAll = () => {
    const isChecked = checkedState.length === dataSet.length;

    return (
      <>
        {dataSet.length > 0 && (
          <HeaderContainer>
            <Checkbox
              checked={isChecked}
              onChange={() => setCheckedState(isChecked ? [] : dataSet)}
            />
            {HeaderBoxLabel && <HeaderBoxLabel />}
          </HeaderContainer>
        )}
      </>
    );
  };

  const SelectOne = ({ value }: { value: T2 }) => {
    const item = checkedState.find(item => item === value);
    const isChecked = !!item;

    const handleChange = () => {
      if (isChecked) {
        setCheckedState([...checkedState].filter(state => state !== item));
      } else {
        setCheckedState([...checkedState, value]);
      }
    };

    return (
      <>{value && <Checkbox checked={isChecked} onChange={handleChange} />}</>
    );
  };

  return {
    accessor,
    width,
    Header: () => <SelectAll />,
    Cell: ({ value }: { value: T2 }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SelectOne value={value} />
      </div>
    ),
  } as Column<T1>;
}
