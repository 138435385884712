import { TableSortFilterParams } from 'common/components/DataTable';
import { abbreviateRole } from 'utils/helperFunction';
import { RoleType } from '../../common/models/role';

export const userTableFilters: TableSortFilterParams[] = [
  {
    options: [
      { label: abbreviateRole(RoleType.Admin), value: RoleType.Admin },
      { label: abbreviateRole(RoleType.Analyst), value: RoleType.Analyst },
      {
        label: abbreviateRole(RoleType.FieldRep),
        value: RoleType.FieldRep,
      },
      {
        label: abbreviateRole(RoleType.HarvestCo),
        value: RoleType.HarvestCo,
      },
    ],
    category: {
      key: 'role.roleName__eq',
      label: 'Role',
    },
  },
];
