import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const versionSliceName = 'versionSlice';

export type VersionSliceState = {
  firstLoadVersion: null | string;
  showRefreshBtn: boolean;
};

export const versionSlice = createSlice({
  name: versionSliceName,
  initialState: { firstLoadVersion: null, showRefreshBtn: false },
  reducers: {
    setVersion: (state: VersionSliceState, action: PayloadAction<string>) => {
      state.firstLoadVersion = action.payload;
    },
    compareVersions: (
      state: VersionSliceState,
      action: PayloadAction<string | null>,
    ) => {
      const responseVersion = action.payload;

      if (
        state.firstLoadVersion &&
        state.firstLoadVersion !== responseVersion
      ) {
        state.showRefreshBtn = true;
      }
    },
  },
});
