import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/react';
import { ErrorResponse } from 'common/models';
import * as notificationService from 'common/services/notification';
import { StatusCodes } from 'http-status-codes';
import { isApiError } from './isApiError';
import { HandledException } from './utils/handledException';
import { HandledServerException } from './utils/handledServerException';

export const sentryErrorHandledProperty = 'Error-handling';

type RequestError =
  | 'FETCH_ERROR'
  | 'PARSING_ERROR'
  | 'TIMEOUT_ERROR'
  | 'CUSTOM_ERROR';

const getDefaultMessage = (
  data: ErrorResponse,
  status: number | RequestError,
) => {
  let message: string;

  switch (status) {
    case StatusCodes.BAD_REQUEST:
    case StatusCodes.UNAUTHORIZED:
    case StatusCodes.FORBIDDEN:
      message = data.message;
      break;

    case 'FETCH_ERROR':
      if (navigator.onLine) {
        message = 'Connection to servers is not available.';
      } else {
        message = 'No Internet Connection.';
      }
      break;

    case StatusCodes.INTERNAL_SERVER_ERROR:
    default:
      message = 'Unable to complete request.';
  }
  return message;
};

function getLogMessage(error: FetchBaseQueryError): string {
  let message: string;

  switch (error.status) {
    case 'FETCH_ERROR':
    case 'PARSING_ERROR':
    case 'CUSTOM_ERROR':
      message = error.error;
      break;
    default: {
      const errorResponse = error.data as ErrorResponse;
      message =
        errorResponse.message ?? 'Fetch failed with unknown error response.';
      break;
    }
  }

  return message;
}

const handleApiError = (
  error: FetchBaseQueryError,
  clientMessage?: string,
  componentName?: string,
): void => {
  const isConflictError =
    typeof error.status === 'number' && error.status === StatusCodes.CONFLICT;

  if (isConflictError) {
    notificationService.showErrorMessage('Please refresh the page.');
    return;
  }

  const errorResponse = error.data as ErrorResponse;
  const message =
    clientMessage ?? getDefaultMessage(errorResponse, error.status);
  const isUnauthorized =
    typeof error.status === 'number' &&
    error.status === StatusCodes.UNAUTHORIZED;
  const isFetchError =
    typeof error.status === 'string' && error.status === 'FETCH_ERROR';

  const additionalInfo = {
    contexts: {
      component: { 'Error triggered in': componentName ?? 'N/A' },
      originalException: error,
      [`${sentryErrorHandledProperty}`]: { 'Is error handled': 'yes' },
    },
  };

  if (!isUnauthorized && !isFetchError) {
    // Wrap error in a new error to get more descriptive title in Sentry
    const newError =
      typeof error.status === 'number'
        ? new HandledServerException(`${error.status}: ${getLogMessage(error)}`)
        : new HandledException(getLogMessage(error));

    Sentry.captureException(newError, additionalInfo);
  }

  notificationService.showErrorMessage(message);
};

/**
 * Handles common errors, such as API errors, by logging and notifying the user.
 * Any other errors are re-thrown for the global error boundary to handle.
 *
 * @param error required object containing data and error status.
 * @param clientMessage a message to notify the user with, instead of the default.
 * @param componentName optional string to help identify where error occurred.
 */

export const handleError = (
  error: unknown,
  clientMessage?: string,
  componentName?: string,
): void => {
  if (isApiError(error)) {
    handleApiError(error, clientMessage, componentName);
  } else {
    // Rethrowing so the Sentry ErrorBoundary component catches error
    throw error;
  }
};
