import { mobile } from 'common/styles/breakpoints';
import {
  grey,
  lighterNavy,
  orange,
  tableBodyGrey,
  tableHeaderGrey,
} from 'common/styles/colors';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  @media (max-width: ${mobile}) {
    justify-content: center;
  }

  p {
    color: ${grey};
    margin: 0;
    width: 25px;
    text-align: center;
  }

  button {
    padding-right: 0;
    padding-left: 0;
  }

  .viewing-page {
    color: ${lighterNavy};
  }

  .viewing-page-underscore {
    background-color: ${orange};
  }
`;

export const StyledSpan = styled.span`
  color: ${tableHeaderGrey};

  @media (max-width: ${mobile}) {
    text-align: center;
  }
`;

export const PageSizeInput = styled.input`
  width: 35px;
  margin: 0 5px;
  text-align: center;
  background: none;
  border: 1px solid ${tableHeaderGrey};
  border-radius: 5px;
  font-size: 15px;
  color: ${tableBodyGrey};

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;
