import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';

// These numbers allow us to easily sort below and there is no need
// to make these comparable to other sizes because each variety only
// has either number or string sizes.
const stringSizeOrder: Record<string, number> = {
  SML: 1,
  MED: 2,
  LGE: 3,
  JMB: 4,
  COL: 5,
  MAM: 6,
  SCL: 7,
};

export function sortSizes(sizes: Size[]) {
  const numberSizes: Size[] = [];
  const stringSizes: Size[] = [];

  sizes.forEach(size => {
    if (stringSizeOrder[size.value]) {
      stringSizes.push(size);
    } else {
      numberSizes.push(size);
    }
  });

  return numberSizes
    .sort(
      (first, second) => parseInt(first.value, 10) - parseInt(second.value, 10),
    )
    .concat(
      stringSizes.sort(
        (first, second) =>
          stringSizeOrder[first.value] - stringSizeOrder[second.value],
      ),
    );
}

export function sortSizeEstimates(estimates: SizeEstimate[]) {
  return estimates.sort((first, second) => {
    const firstAsNumber = parseInt(first.size.value, 10);
    const secondAsNumber = parseInt(second.size.value, 10);

    const firstVal = Number.isNaN(firstAsNumber)
      ? stringSizeOrder[first.size.value]
      : firstAsNumber;
    const secondVal = Number.isNaN(secondAsNumber)
      ? stringSizeOrder[second.size.value]
      : secondAsNumber;

    return firstVal - secondVal;
  });
}

export function createSizeFormValues(
  block: BlockDetails<WithAllSeasonData>,
  varieties?: Variety[],
): SizeEstimate[] {
  if (!varieties) {
    return [];
  }

  const variety = varieties?.find(vrt => vrt.id === block.variety?.id);

  if (!variety) {
    return [];
  }

  // Due to a bug with the first block master import, some blocks
  // have size estimates that are not for the block's variety.
  // Because of this, we have to find sizes inside a flattened array.
  const allSizes = varieties?.reduce((acc, vrt) => {
    return [...acc, ...vrt.sizes];
  }, [] as Size[]);

  let sizeEstimates: SizeEstimate[] = [];

  const blockEstimates = block.seasonData?.sizeEstimates;

  sizeEstimates = blockEstimates?.length
    ? blockEstimates.map(est => ({
        id: est.id,
        percentValue: est.percentValue,
        size: allSizes.find(sze => sze.id === est.sizeId) as Size,
      }))
    : variety.sizes.map(size => ({
        percentValue: 0,
        size,
      }));

  // The order of the array matters to 'useForm' so sorting must
  // be done here to ensure correct value tracking.
  return sortSizeEstimates(sizeEstimates);
}
