import { mobile } from 'common/styles/breakpoints';
import { green, white } from 'common/styles/colors';
import { boxShadow } from 'common/styles/page';
import styled from 'styled-components';

export const ReloadBtn = styled.button`
  width: 250px;
  padding: 10px;
  position: fixed;
  background-color: ${green};
  border: none;
  border-radius: 25px;
  box-shadow: ${boxShadow};
  font-family: KanitRegular;
  color: ${white};
  z-index: 2;
  right: 37%;
  bottom: 7%;

  animation: fadeIn 2s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  :hover {
    opacity: 0.9;
  }

  @media (max-width: ${mobile}) {
    right: 17%;
    bottom: 10%;
  }
`;
