import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { VarietalSummaryRow, Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';

const varietyBaseUrl = '/variety';

const varietiesTag = 'Varieties';
const sizesTag = 'Sizes';

export const varietyApi = createApi({
  reducerPath: 'varietyApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: [varietiesTag, sizesTag],

  endpoints: builder => ({
    getVarieties: builder.query<Variety[], void>({
      query: () => ({
        url: `${varietyBaseUrl}`,
      }),
      providesTags: (_, err) => (err ? [] : [varietiesTag]),
    }),

    getSizes: builder.query<Size[], void>({
      query: () => ({
        url: `${varietyBaseUrl}/sizes`,
      }),
      providesTags: (_, err) => (err ? [] : [sizesTag]),
    }),

    getVarietalSummary: builder.query<
      VarietalSummaryRow[],
      { seasonId: number }
    >({
      query: ({ seasonId }) => ({
        url: `${varietyBaseUrl}/summary/${seasonId}`,
      }),
      forceRefetch: () => true,
    }),
  }),
});

export const {
  useGetVarietiesQuery,
  useGetSizesQuery,
  useGetVarietalSummaryQuery,
} = varietyApi;
