export enum SyncTaskDescriptor {
  None,
  Varieties,
  Growers,
  HarvestData,
  Pools,
  Contractors,
  IncompleteBlocks,
  Estimates,
}
