import { TableSortFilterParams } from 'common/components/DataTable';
import { BlockStatus } from 'common/models';
import { GibbRate } from 'common/models/evaluation';
import {
  getEnumLabels,
  getKeyFromEnumLabel,
  toEnumLabel,
} from 'utils/enumFunctions';
import { OrderOps, SearchOps } from './helpers';

export enum BlocksSortKeys {
  sizeKey = `${OrderOps.DESC}sizePercent`,
}

export enum BlocksFilterKeys {
  varietyKey = `variety${SearchOps.EQUALS}`,
  subVarietyKey = `subVariety${SearchOps.EQUALS}`,
  areaKey = `area${SearchOps.EQUALS}`,
  showAllKey = `showAll${SearchOps.EQUALS}`,
  statusKey = `blockStatus${SearchOps.EQUALS}`,
  blockIdkey = `blockId${SearchOps.ICONTAINS}`,
  gibbKey = `gibb${SearchOps.EQUALS}`,
  gibbRateKey = `gibbRate${SearchOps.EQUALS}`,
}

export const varietyFilter: TableSortFilterParams = {
  options: [],
  category: {
    key: BlocksFilterKeys.varietyKey,
    label: 'Variety',
  },
};

export const subVarietyFilter: TableSortFilterParams = {
  options: [],
  category: {
    key: BlocksFilterKeys.subVarietyKey,
    label: 'Sub-Variety',
  },
};

export const areaFilter: TableSortFilterParams = {
  options: [],
  category: {
    key: BlocksFilterKeys.areaKey,
    label: 'Area',
  },
};

export const showAllBlocksFilter: TableSortFilterParams = {
  subHeader: 'Last Two Weeks (default)',
  options: [
    {
      label: 'Show All',
      value: 'true',
    },
  ],
  category: {
    key: BlocksFilterKeys.showAllKey,
    label: 'Date Range',
  },
};

export const blockStatusFilter: TableSortFilterParams = {
  options: getEnumLabels(BlockStatus).map(status => ({
    label:
      status === toEnumLabel(BlockStatus[BlockStatus.No_Status])
        ? `(${status})`
        : status,
    value: getKeyFromEnumLabel(BlockStatus, status)?.toString() || '-1',
  })),
  category: {
    key: BlocksFilterKeys.statusKey,
    label: 'Block Status',
  },
};

export const blockIdFilter: TableSortFilterParams = {
  options: [],
  category: {
    key: BlocksFilterKeys.blockIdkey,
    label: 'Block ID',
  },
};

export const gibbFilter: TableSortFilterParams = {
  options: [
    [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  ],
  category: {
    key: BlocksFilterKeys.gibbKey,
    label: 'Gibb',
  },
  singleSelect: true,
};

export const gibbRateFilter: TableSortFilterParams = {
  options: getEnumLabels(GibbRate)
    .filter(rate => rate !== GibbRate[GibbRate.None])
    .map(rate => ({
      label: rate,
      value: getKeyFromEnumLabel(GibbRate, rate)?.toString() || '-1',
    })),
  category: {
    key: BlocksFilterKeys.gibbRateKey,
    label: 'Gibb Rate',
  },
};

export const sizeSort: TableSortFilterParams = {
  options: [],
  category: {
    key: BlocksSortKeys.sizeKey,
    label: 'Size',
  },
  singleSelect: true,
};
