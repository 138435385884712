import CancelIcon from '@mui/icons-material/Cancel';
import { ReactElement } from 'react';
import { BubbleContainer } from './styles';

interface BubbleProps {
  categoryLabel: string;
  category: string;
  valueLabel: string;
  value: string;
  removeBubble: (value: string, category: string) => void;
}

export const SortFilterBubble = ({
  categoryLabel,
  category,
  valueLabel,
  value,
  removeBubble,
}: BubbleProps): ReactElement => {
  return (
    <BubbleContainer>
      <p className='bubble-prop'>{categoryLabel}: </p>
      <p className='bubble-label'>{valueLabel}</p>
      <button type='button' onClick={() => removeBubble(value, category)}>
        <CancelIcon />
      </button>
    </BubbleContainer>
  );
};
