import { useAppSelector } from 'app/redux';
import { useGetAreasQuery } from 'common/api/areaApi';
import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { BlockEvalData } from 'common/api/dto/growerBlockApiTypes';
import { useGetBlockSeasonEvalDataQuery } from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { useGetSubVarietiesQuery } from 'common/api/subVarietyApi';
import { useGetSizesQuery, useGetVarietiesQuery } from 'common/api/varietyApi';
import {
  DataTable,
  DataTableMobile,
  DataTableProps,
  MobileStyling,
  Option,
  TableSortFilterParams,
} from 'common/components/DataTable';
import { expandableRowKey } from 'common/components/DataTable/TableBody/TableBody';
import { usePSFQuery } from 'common/hooks';
import useWindowSize from 'common/hooks/useWindowSize';
import { BlockStatus, PaginatedResult } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { useRbac } from 'features/rbac';
import { FC, useEffect, useMemo } from 'react';
import { UseQuery } from 'rtk-query-config';
import { Constants } from 'utils/constants';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import {
  areaFilter,
  blockIdFilter,
  BlocksFilterKeys,
  blockStatusFilter,
  gibbFilter,
  gibbRateFilter,
  sizeSort,
  subVarietyFilter,
  varietyFilter,
} from 'utils/tableFilters/blockFilters';
import {
  growerIdFilter,
  parentGrowerFilter,
} from 'utils/tableFilters/growerFilters';
import {
  createAreaFilters,
  createSizesSort,
  createSubVarietyFilters,
  createVarietyFilters,
} from 'utils/tableFilters/helpers';
import { AsyncCsvButton } from '../../../common/components/CsvButton/AsyncCsvButton';
import {
  BlockEvalTableItem,
  useBlockEvalTableData,
} from '../hooks/useBlockEvalTableData';
import { useBlockEvalTableDataMobile } from '../hooks/useBlockEvalTableDataMobile';
import {
  allEvalTableTitle,
  blankEvalTableTitle,
  myBocksTableTitle,
} from '../pages/EvalEstMainView';
import { formatAllEvalCsvData } from '../utils/exportAllCsvUtils';

const evalStatusOptions: Option[] =
  ({ ...blockStatusFilter }.options as Option[]).filter(option =>
    [BlockStatus.Pending, BlockStatus.Active, BlockStatus.Harvested]
      .map(status => status.toString())
      .includes(option.value),
  ) || [];

const evalStatusFilter = { ...blockStatusFilter, options: evalStatusOptions };

const blankTableFilters: TableSortFilterParams[] = [
  parentGrowerFilter,
  growerIdFilter,
  blockIdFilter,
  gibbFilter,
  gibbRateFilter,
  varietyFilter,
  subVarietyFilter,
  areaFilter,
];

const blockTableFilters: TableSortFilterParams[] = [
  ...blankTableFilters,
].toSpliced(3, 0, evalStatusFilter);

export const BlockEvalTable: FC<{
  title: string;
  blockQuery: UseQuery<PaginatedResult<BlockDetails<WithAllSeasonData>>>;
  psfKey: PSFKeys;
}> = ({ title, blockQuery, psfKey }) => {
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const { query, setQuery } = usePSFState(psfKey);
  const { userHasPermission } = useRbac();
  const {
    data,
    isLoading,
    isFetching,
    pagination,
    applySorts,
    applyFilters,
    sorts,
    filters,
    error: getBlocksError,
  } = usePSFQuery<PaginatedResult<BlockDetails<WithAllSeasonData>>>(
    blockQuery,
    {},
    { ...query },
    setQuery,
    selectedSeason?.id ? { seasonId: selectedSeason.id } : {},
  );
  const growerBlocks = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useBlockEvalTableData(
    isLoading || isFetching,
    growerBlocks,
  );
  const columnsMobile = useBlockEvalTableDataMobile();
  const { data: varieties = [], error: varietyError } = useGetVarietiesQuery();
  const { data: sizes = [], error: sizesError } = useGetSizesQuery();
  const { data: subVarieties = [], error: subVarietyError } =
    useGetSubVarietiesQuery();
  const { data: areas = [], error: areaError } = useGetAreasQuery();
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  const detailsUrlBuilder = (block: BlockEvalTableItem) =>
    `${Constants.routes.EVAL_EST_MAIN}/${block?.blockInfo.id}`;

  const blockTableSorts = useMemo(() => {
    if (sizes.length > 0 && !sizesError) {
      const sortWithOptions = sizeSort;

      sortWithOptions.options = createSizesSort(sizes);
      return [sortWithOptions];
    }
    return [sizeSort];
  }, [sizes, sizesError]);

  const showExportButton =
    !isLoading &&
    !isFetching &&
    (title === allEvalTableTitle || title === myBocksTableTitle) &&
    userHasPermission('export:eval-and-est');

  const ExportAllEvalEstBtn = () => (
    <AsyncCsvButton<BlockEvalData[]>
      label={`Export ${selectedSeason?.year}`}
      fetcher={useGetBlockSeasonEvalDataQuery}
      params={{ seasonId: selectedSeason?.id as number }}
      formatter={formatAllEvalCsvData}
      filenameOptions={{
        name: `block_evaluations_${selectedSeason?.year}`,
        appendDate: false,
      }}
    />
  );

  const tableProps: DataTableProps<BlockEvalTableItem> = {
    title,
    unitToPaginate: 'blocks',
    isLoading,
    isFetching,
    columns: isMobile ? columnsMobile : columns,
    data: tableData,
    pagination,
    sortBy: {
      sortParams: blockTableSorts,
      selectedSorts: sorts,
      applySorts,
    },
    filterBy: {
      filterParams:
        title === blankEvalTableTitle ? blankTableFilters : blockTableFilters,
      selectedFilters: filters,
      applyFilters,
    },
    expandableRows: true,
    onCellClick: {
      buildUrlPath: detailsUrlBuilder,
      cellsToIgnore: [expandableRowKey, 'location'],
    },
    onRowClick: isMobile ? detailsUrlBuilder : undefined,
    loadError: !!getBlocksError,
    ...(showExportButton && {
      FooterComponent: ExportAllEvalEstBtn,
    }),
  };

  useMemo(() => {
    if (!varietyError) {
      const filterIdx = blockTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.varietyKey,
      );
      blockTableFilters[filterIdx].options = createVarietyFilters(varieties);
    }
  }, [varieties, varietyError]);

  useMemo(() => {
    if (!subVarietyError) {
      const filterIdx = blockTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.subVarietyKey,
      );
      blockTableFilters[filterIdx].options =
        createSubVarietyFilters(subVarieties);
    }
  }, [subVarieties, subVarietyError]);

  useMemo(() => {
    if (!areaError) {
      const filterIndex = blockTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.areaKey,
      );
      blockTableFilters[filterIndex].options = createAreaFilters(areas);
    }
  }, [areas, areaError]);

  useEffect(() => {
    if (getBlocksError) {
      handleError(getBlocksError, `Unable to load '${title}' blocks.`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBlocksError]);

  return isMobile ? (
    <MobileStyling>
      <DataTableMobile<BlockEvalTableItem> {...tableProps} />
    </MobileStyling>
  ) : (
    <DataTable<BlockEvalTableItem> {...tableProps} />
  );
};
