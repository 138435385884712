import { useAppSelector } from 'app/redux';
import { useGetAreasQuery } from 'common/api/areaApi';
import {
  GrowerWithBlocks,
  GrowerWithoutBlocks,
} from 'common/api/dto/get-grower.dto';
import {
  useGetGrowerDataBySeasonQuery,
  useGetGrowersQuery,
} from 'common/api/growerApi';
import { handleError } from 'common/api/handleError';
import { useGetVarietiesQuery } from 'common/api/varietyApi';
import { AsyncCsvButton } from 'common/components/CsvButton/AsyncCsvButton';
import {
  DataTable,
  DataTableMobile,
  DataTableProps,
  TableSortFilterParams,
} from 'common/components/DataTable';
import { usePSFQuery } from 'common/hooks';
import useWindowSize from 'common/hooks/useWindowSize';
import { PaginatedResult } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { useRbac } from 'features/rbac';
import { FC, useEffect, useMemo } from 'react';
import { Constants } from 'utils/constants';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import {
  areaFilter,
  BlocksFilterKeys,
  varietyFilter,
} from 'utils/tableFilters/blockFilters';
import {
  gapStatusFilter,
  growerIdFilter,
  growerStatusFilter,
  parentGrowerFilter,
} from 'utils/tableFilters/growerFilters';
import {
  createAreaFilters,
  createVarietyFilters,
} from 'utils/tableFilters/helpers';
import {
  GrowerTableItem,
  useGrowerTableData,
} from '../hooks/useGrowerTableData';
import { useGrowerTableDataMobile } from '../hooks/useGrowerTableDataMobile';
import { formatAllGrowerData } from '../utils/exportAllCsvUtils';

const growersTableFilters: TableSortFilterParams[] = [
  parentGrowerFilter,
  growerIdFilter,
  growerStatusFilter,
  varietyFilter,
  areaFilter,
  gapStatusFilter,
];

export const GrowersTable: FC = () => {
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const { query, setQuery } = usePSFState(PSFKeys.Growers);
  const { userHasPermission } = useRbac();
  const {
    data,
    isLoading,
    isFetching,
    pagination,
    applyFilters,
    filters,
    error: getGrowersError,
  } = usePSFQuery<PaginatedResult<GrowerWithBlocks>>(
    useGetGrowersQuery,
    {},
    { ...query },
    setQuery,
    // The component only renders when season exists so we safely assert the value here.
    { seasonId: selectedSeason?.id as number },
  );
  const growers = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useGrowerTableData(growers);
  const columnsMobile = useGrowerTableDataMobile();
  const { data: varieties = [], error: varietyError } = useGetVarietiesQuery();
  const { data: areas = [], error: areaError } = useGetAreasQuery();
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  const showExportButton =
    !isLoading && !isFetching && userHasPermission('export:growers');

  const ExportAllGrowerDataBtn = () => (
    <AsyncCsvButton<GrowerWithoutBlocks[]>
      label={`Export ${selectedSeason?.year}`}
      fetcher={useGetGrowerDataBySeasonQuery}
      params={{ seasonId: selectedSeason?.id as number }}
      formatter={formatAllGrowerData}
      filenameOptions={{
        name: `grower_data_${selectedSeason?.year}`,
        appendDate: false,
      }}
    />
  );

  const tableProps: DataTableProps<GrowerTableItem> = {
    title: 'Growers',
    unitToPaginate: 'growers',
    isLoading,
    isFetching,
    columns: isMobile ? columnsMobile : columns,
    data: tableData,
    pagination,
    onRowClick: grower => `${Constants.routes.GROWER_DETAILS}${grower.id}`,
    filterBy: {
      filterParams: growersTableFilters,
      selectedFilters: filters,
      applyFilters,
    },
    loadError: !!getGrowersError,
    ...(showExportButton && { FooterComponent: ExportAllGrowerDataBtn }),
  };

  useMemo(() => {
    if (!varietyError) {
      const filterIdx = growersTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.varietyKey,
      );
      growersTableFilters[filterIdx].options = createVarietyFilters(varieties);
    }
  }, [varieties, varietyError]);

  useMemo(() => {
    if (!areaError) {
      const filterIndex = growersTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.areaKey,
      );
      growersTableFilters[filterIndex].options = createAreaFilters(areas);
    }
  }, [areas, areaError]);

  useEffect(() => {
    if (getGrowersError) {
      handleError(getGrowersError, 'Unable to load growers');
    }
  }, [getGrowersError]);

  return (
    <>
      {isMobile ? (
        <DataTableMobile<GrowerTableItem> {...tableProps} />
      ) : (
        <>
          <DataTable<GrowerTableItem> {...tableProps} />
        </>
      )}
    </>
  );
};
