import { DataTable, DataTableProps } from 'common/components/DataTable';
import { SyncTaskNotification } from 'common/models/databaseSync/syncNotification';
import { FC } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';
import { taskNames } from '../utils/sync/taskNames';
import { taskStatusNames } from '../utils/sync/taskStatusNames';
import { ErrorTableStyledWrapper } from './SpokaneSyncStyles';

type TaskResultsTableProps = {
  taskResults: SyncTaskNotification[];
};

const TitleCase = styled.span`
  text-transform: capitalize;
`;

export const TaskResultsTable: FC<TaskResultsTableProps> = ({
  taskResults,
}) => {
  const columns: Column<SyncTaskNotification>[] = [
    {
      accessor: 'descriptor',
      Header: 'Sync Task',
      Cell: ({ value }) => <TitleCase>{taskNames[value]}</TitleCase>,
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ value }) => <TitleCase>{[taskStatusNames[value]]}</TitleCase>,
    },
    {
      accessor: 'error',
      Header: 'Message',
      Cell: ({ value }) => <span>{value}</span>,
    },
  ];

  const tableProps: DataTableProps<SyncTaskNotification> = {
    isLoading: false,
    isFetching: false,
    columns,
    data: taskResults,
  };

  return (
    <ErrorTableStyledWrapper>
      <DataTable<SyncTaskNotification> {...tableProps} />
    </ErrorTableStyledWrapper>
  );
};
