import { skipToken } from '@reduxjs/toolkit/query';
import { useAppDispatch, useAppSelector } from 'app/redux';
import { handleError } from 'common/api/handleError';
import {
  useGetHarvestCrewsQuery,
  useGetInvoiceSummariesQuery,
  useGetPayrollDataByContractorQuery,
} from 'common/api/harvestDataApi';
import { DataTable, DataTableProps } from 'common/components/DataTable';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import {
  useWeekSwitcherState,
  WeekSwitcherIds,
} from 'common/components/WeekSwitcher/useWeekSwitcherState';
import { WizardDropdownOption } from 'common/components/WizardControls/WizardDropdown';
import { Contractor } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import { blueShadeHeader } from 'common/styles/colors';
import { boxShadow } from 'common/styles/page';
import { FC, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { getISODateOnlyString } from 'utils/dateTimeHelpers';
import { createContractorOptions } from 'utils/formValues/dropdownOptions';
import { PayrollBody } from '../components/PayrollBody/PayrollBody';
import { PayrollHeader } from '../components/PayrollHeader/PayrollHeader';
import {
  PayrollSumTableItem,
  usePayrollSumTableData,
} from '../hooks/usePayrollSumTableData';
import {
  allContractorsCode,
  allContractorsLabel,
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from '../PayrollSlice';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: ${boxShadow};
  padding: 20px 0;
  border-radius: 8px;
  width: 100%;

  h1 {
    margin: 0;
    font-family: KanitLight;
    font-size: 32px;
    color: ${blueShadeHeader};
  }

  h4 {
    margin: 0;
    padding-top: 10px;
    font-family: KanitSemiBold;
    font-size: 24px;
    color: ${blueShadeHeader};
  }

  @media (max-width: ${mobile}) {
    padding: 5px;
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  with: 100%;
  height: 100px;
`;

export const HarvestPayrollView: FC = () => {
  const dispatch = useAppDispatch();
  const { contractorId } = useParams<{ contractorId: string }>();
  const {
    data: crewData,
    isLoading: isLoadingCrews,
    error: crewError,
  } = useGetHarvestCrewsQuery();
  const { state: week } = useWeekSwitcherState(WeekSwitcherIds.HarvPayroll);
  const { selectedContractor } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const {
    data: payrollData,
    error: payrollDataError,
    isLoading,
    isFetching,
  } = useGetPayrollDataByContractorQuery(
    selectedContractor?.id
      ? {
          contractorId: selectedContractor.id,
          startDate: getISODateOnlyString(week.startDate),
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: summaryData,
    error: summaryDataError,
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
  } = useGetInvoiceSummariesQuery(
    !selectedContractor?.id && selectedContractor?.code === allContractorsCode
      ? {
          startDate: getISODateOnlyString(week.startDate),
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { columns, data } = usePayrollSumTableData(summaryData || []);

  const tableProps: DataTableProps<PayrollSumTableItem> = {
    isLoading: false,
    isFetching: false,
    columns,
    data,
    onRowClick: row =>
      `${Constants.routes.HARVEST_PAYROLL}/${row.contractorId}`,
  };

  const allContractorsOption = useMemo(
    () => ({
      label: allContractorsLabel,
      value: { code: allContractorsCode } as Contractor,
    }),
    [],
  );

  const contractorOptions: WizardDropdownOption<Contractor>[] = useMemo(
    () => [
      allContractorsOption,
      ...createContractorOptions(crewData?.fieldLaborContractors),
    ],
    [allContractorsOption, crewData],
  );

  // Listen to the url changes and update the selected contractor accordinly.
  useEffect(() => {
    if (
      contractorOptions.length > 1 &&
      (contractorId === allContractorsCode ||
        typeof parseInt(contractorId, 10) === 'number')
    ) {
      const contractor = contractorOptions?.find(
        option => option.value.id === parseInt(contractorId, 10),
      );
      dispatch(
        payrollSlice.actions.setContractor(
          contractor?.value || allContractorsOption.value,
        ),
      );
    }
  }, [allContractorsOption, contractorId, contractorOptions, dispatch]);

  useEffect(() => {
    if (crewError) {
      handleError(crewError, 'Unable to load contractors.');
    }
    if (payrollDataError) {
      handleError(payrollDataError, "Unable to fetch contractor's payroll.");
    }
    if (summaryDataError) {
      handleError(
        payrollDataError,
        "Unable to fetch this week's payroll summary.",
      );
    }
  }, [crewError, payrollDataError, summaryDataError]);

  return (
    <StyledContainer>
      <PayrollHeader
        summaryData={summaryData}
        payrollData={payrollData}
        contractorOptions={contractorOptions}
        isLoadingContractors={isLoadingCrews}
        isLoadingPayrollData={isLoading || isFetching}
        isLoadingSummary={isLoadingSummary || isFetchingSummary}
      />
      {selectedContractor?.id && (
        <WithLoadingOverlay isLoading={isLoading || isFetching}>
          {payrollData && payrollData.picks.length ? (
            <PayrollBody payrollData={payrollData} />
          ) : (
            <NoData>
              No payroll information was found for this contractor.
            </NoData>
          )}
        </WithLoadingOverlay>
      )}
      {!selectedContractor?.id &&
        selectedContractor?.code === allContractorsCode && (
          <WithLoadingOverlay isLoading={isLoadingSummary || isFetchingSummary}>
            {summaryData && summaryData.length ? (
              <div style={{ margin: '10px 20px' }}>
                <DataTable<PayrollSumTableItem> {...tableProps} />
              </div>
            ) : (
              <NoData>No payroll information was found for this week.</NoData>
            )}
          </WithLoadingOverlay>
        )}
    </StyledContainer>
  );
};
