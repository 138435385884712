import { Skeleton } from '@mui/material';
import { defaultPaginationConfig } from 'common/hooks';
import { Fragment, MouseEventHandler, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, TableInstance } from 'react-table';
import { DataTableProps, RowBuilder } from '../DataTable';

export const expandableRowKey = 'buildExpandedRow';
export const blueRowClass = 'blue-row';

type TableBodyProps<D extends Record<string, unknown>> = Pick<
  DataTableProps<D>,
  'pagination' | 'onRowClick' | 'onCellClick' | 'loadError'
> & {
  isLoadOrFetch: boolean;
  tableInstance: TableInstance<D>;
  tableRows: Row<D>[];
};

export const TableBody = <D extends Record<string, unknown>>({
  isLoadOrFetch,
  pagination,
  onRowClick,
  onCellClick,
  tableInstance,
  tableRows,
  loadError,
}: TableBodyProps<D>): ReactElement => {
  const history = useHistory();
  const hasTableData = tableRows.length > 0;
  const emptyRowCount =
    (pagination?.pageSize || defaultPaginationConfig.pageSize) -
    tableRows.length;
  const approximateRowHeight = '45.2px';

  const { getTableBodyProps, headerGroups, prepareRow } = tableInstance;
  const { headers } = headerGroups[0];

  /** @deprecated Click should be handled by the cell. */
  const handleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    link: string | undefined,
  ): MouseEventHandler<HTMLTableRowElement> | undefined => {
    const { target } = event;

    if (target instanceof HTMLInputElement && target.type === 'checkbox') {
      return;
    }

    if (typeof link === 'string') {
      history.push(link);
    }
  };

  const handleCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
    row: Row<D>,
  ): void => {
    const { currentTarget } = event;
    const cellsToIgnore = onCellClick?.cellsToIgnore || [];
    const isCellToIgnore = cellsToIgnore.some(key =>
      currentTarget.id.includes(key.toString()),
    );

    if (onCellClick && !isCellToIgnore) {
      history.push(onCellClick.buildUrlPath(row.original));
    }
  };

  return (
    <tbody {...getTableBodyProps()}>
      {/* Loading display. */}
      {isLoadOrFetch && (
        <>
          {Array.from({
            length: pagination?.pageSize || defaultPaginationConfig.pageSize,
          }).map((_, index) => (
            <tr
              key={`skeleton-row-${index + 1}`}
              className={index % 2 ? blueRowClass : ''}
            >
              {headers.map((_, index) => (
                <td key={`skeleton-cell-${index + 1}`}>
                  <Skeleton height={25} />
                </td>
              ))}
            </tr>
          ))}
        </>
      )}

      {/* Data row display. */}
      {!isLoadOrFetch && hasTableData && (
        <>
          {tableRows.map((row, index) => {
            let link: undefined | string;
            const baseClass = index % 2 ? blueRowClass : '';
            const withClickableClass = baseClass.concat(
              onRowClick || onCellClick ? ' clickable-row' : '',
            );
            const buildExpandedRow = row.original[
              expandableRowKey
            ] as RowBuilder;

            prepareRow(row);

            if (onRowClick) {
              link = onRowClick(row.original);
            }

            return (
              <Fragment key={row.index}>
                <tr
                  {...row.getRowProps()}
                  className={withClickableClass}
                  onClick={e =>
                    onRowClick ? handleRowClick(e, link) : undefined
                  }
                >
                  {row.cells.map(cell => {
                    const cellProps = cell.getCellProps();

                    return (
                      <td
                        {...cellProps}
                        id={cellProps.key.toString()}
                        onClick={e =>
                          onCellClick ? handleCellClick(e, row) : undefined
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded && buildExpandedRow && (
                  <tr className={baseClass}>
                    <td>{/* placeholder cell */}</td>
                    <td colSpan={row.cells.length - 1}>{buildExpandedRow()}</td>
                  </tr>
                )}
              </Fragment>
            );
          })}

          {/* Add empty rows if the page does not have every row filled. */}
          {pagination &&
            !!emptyRowCount &&
            Array.from({ length: emptyRowCount }).map((_, index) => (
              <tr
                key={`extra-row-${index + 1}`}
                className={(index - tableRows.length) % 2 ? blueRowClass : ''}
              >
                {headers.map((_, index) => (
                  <td
                    key={`extra-cell-${index + 1}`}
                    style={{ height: approximateRowHeight }}
                  >
                    {/* This is an empty row. */}
                  </td>
                ))}
              </tr>
            ))}
        </>
      )}

      {/* No data display. */}
      {!isLoadOrFetch && !hasTableData && (
        <tr>
          <td
            colSpan={headers.length}
            style={{ textAlign: 'center', margin: '20px 0' }}
          >
            {loadError
              ? 'There was an issue loading data.'
              : 'No data available.'}
          </td>
        </tr>
      )}
    </tbody>
  );
};
