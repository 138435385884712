import { SyncTaskStatus } from 'common/models/databaseSync/syncTaskStatus';

/**
 * Maps {@link SyncTaskStatus} values to their display names (lowercase).
 */
export const taskStatusNames = {
  [SyncTaskStatus.None]: 'N/A',
  [SyncTaskStatus.Started]: 'in progress',
  [SyncTaskStatus.Complete]: 'successful',
  [SyncTaskStatus.Failed]: 'failed',
  [SyncTaskStatus.Skipped]: 'skipped',
};
