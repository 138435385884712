import { SyncTaskDescriptor } from 'common/models/databaseSync/syncTaskDescriptor';

/**
 * Maps {@link SyncTaskDescriptor} values to their display names (lowercase).
 */
export const taskNames = {
  [SyncTaskDescriptor.None]: 'N/A',
  [SyncTaskDescriptor.Varieties]: 'varieties',
  [SyncTaskDescriptor.Growers]: 'growers',
  [SyncTaskDescriptor.HarvestData]: 'harvest data',
  [SyncTaskDescriptor.Pools]: 'pools',
  [SyncTaskDescriptor.Contractors]: 'contractors',
  [SyncTaskDescriptor.IncompleteBlocks]: 'blocks pending setup',
  [SyncTaskDescriptor.Estimates]: 'estimates',
};
