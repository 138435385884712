import { TableContainer } from 'common/components/DataTable';
import { blueRowClass } from 'common/components/DataTable/TableBody/TableBody';
import { DetailFormContext } from 'common/components/DetailControls/DetailForm';
import { CmsDate } from 'common/models/cmsDate';
import { Estimate } from 'common/models/growerBlock/estimate';
import * as notificationService from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { blueShadeHeader, darkNavy, lighterNavy } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { BlockInfo } from 'features/grower-views/components/BlockDetailsForm';
import { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  margin: ${FormPadding};

  & > div {
    box-shadow: none;
    border-style: solid;
    border: solid 2px ${blueShadeHeader};
  }

  @media screen and (max-width: ${mobile}) {
    margin: ${FormPadding} 2px;
    & > div {
      padding-left: 2px;
      padding-right: 2px;
    }
    table {
      table-layout: fixed;
    }
    tr {
      & > *:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      & > *:nth-child(2) {
        width: 45px;
      }
      & > *:nth-child(3) {
        display: none;
      }
      & > *:nth-child(4) {
        width: 100px;
      }
    }
  }

  tr > th:last-of-type,
  td:last-of-type {
    text-align: right;
  }

  tr > th,
  td {
    color: ${darkNavy};
  }

  tr > td:nth-child(2) {
    font-weight: 600;
    text-align: center;
  }

  tr > th:nth-child(2) {
    text-align: center;
  }

  tr:first-of-type {
    & > td {
      font-family: 'KanitSemiBold';
    }
  }
`;

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EstimateHistoryDropDown = styled.label`
  display: flex;
  align-items: center;

  select {
    color: ${lighterNavy};
    border-radius: 4px;
    border-color: ${darkNavy};
    padding: 0 25px 0 10px;
    margin-left: 0.5em;
    width: 100%;
    cursor: pointer;

    /* Dropdown selector icon */
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
      calc(100% - 14px) calc(11px),
      calc(100% - 9px) calc(11px);
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
  }
`;

const TableHeader = styled.div`
  display: flex;
  margin-bottom: ${FormPadding};
  justify-content: space-between;
`;

interface HistoryTableProps {
  conversionRate: number | undefined | null;
  estimates: Estimate[] | undefined;
}

type EstimateLogItem = Omit<Estimate, 'createdAt' | 'createdByUser'> & {
  createdAt: CmsDate | null;
  year: number | undefined;
  createdBy: string;
};

export const EstimatesHistoryTable: FC<HistoryTableProps> = ({
  conversionRate,
  estimates,
}) => {
  const [isBin, setIsBin] = useState<boolean>(true);
  const [hasConversionRate, setHasConversionRate] = useState<boolean>(true);

  const estimateItems = useMemo(
    () =>
      (estimates ?? []).reduce(
        (acc, estimate, idx, log) => {
          const isInitial = idx === log.length - 1;
          const createdAt = CmsDate.parse(estimate.createdAt);
          const createdBy = estimate.createdByUser
            ? `${estimate.createdByUser.firstName} ${estimate.createdByUser.lastName}`
            : '';
          const item = {
            ...estimate,
            createdAt,
            year: createdAt?.toDate().getFullYear(),
            createdBy,
          };

          if (isInitial) {
            acc.initial = item;
          } else {
            acc.history.push(item);
          }

          return acc;
        },
        {
          initial: null as EstimateLogItem | null,
          history: [] as EstimateLogItem[],
        },
      ),
    [estimates],
  );

  const handleConversionClick = () => {
    if (!Number(conversionRate)) {
      setHasConversionRate(false);
      notificationService.showErrorMessage('Conversion rate not available.');
    }
  };

  const editContext = useContext(DetailFormContext);

  return (
    <TableWrapper>
      <TableContainer>
        <TableHeader>
          <BlockInfo>Estimate History</BlockInfo>
          <DropDownWrapper>
            <EstimateHistoryDropDown htmlFor='unitSelector'>
              Unit
              <select
                disabled={
                  hasConversionRate && !editContext.editable ? undefined : true
                }
                onClick={handleConversionClick}
                onChange={() => setIsBin(!isBin)}
                name='unitSelector'
                id='unitSelector'
              >
                <option value='bins'>Bin</option>
                <option value='cartons'>Carton</option>
              </select>
            </EstimateHistoryDropDown>
          </DropDownWrapper>
        </TableHeader>
        <table>
          <thead>
            <tr>
              {estimateItems.initial ? (
                <>
                  <th>{`${estimateItems.initial.createdBy} (initial)`}</th>
                  <th>
                    {!isBin && conversionRate
                      ? conversionRate * estimateItems.initial.estimatedBins
                      : estimateItems.initial.estimatedBins}
                  </th>
                  <th>
                    {estimateItems.initial.isSynched === null
                      ? 'Not synced'
                      : ''}
                  </th>
                  <th>{estimateItems.initial.createdAt?.toDateString()}</th>
                </>
              ) : (
                <>
                  <th>NONE</th>
                  <th>{}</th>
                  <th>{}</th>
                  <th>{}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {estimateItems.history?.map((row, idx) => {
              return (
                <tr
                  key={`${row.createdAt?.toISO()}${row.estimatedBins + idx}`}
                  className={idx % 2 ? blueRowClass : ''}
                >
                  <td>{`${row.createdBy}${idx === 0 ? ' (latest)' : ''}`}</td>
                  <td>
                    {!isBin && conversionRate
                      ? conversionRate * row?.estimatedBins
                      : row?.estimatedBins}
                  </td>
                  <td>{row.isSynched === null ? '(not synced)' : ''}</td>
                  <td>{row?.createdAt?.toDateString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableContainer>
    </TableWrapper>
  );
};
