import {
  useGetAllOrOtherBlocksWithEvaluationQuery,
  useGetBlocksAssignedToFieldRepQuery,
  useGetBlocksPendingEvaluationQuery,
} from 'common/api/growerBlockApi';
import { useRbac } from 'features/rbac';
import { FC } from 'react';
import styled from 'styled-components';
import { PSFKeys } from 'utils/hooks/usePSFState';
import { BlockEvalTable } from '../components/BlockEvalTable';
import { VarietalSumTable } from '../components/VarietalSummaryTable';

const Divider = styled.div`
  height: 20px;
`;

export const allEvalTableTitle = 'All Evaluations';
export const myBocksTableTitle = 'My Blocks/Evaluations';
export const blankEvalTableTitle = 'Blank Evaluations';
const otherEvalTableTitle = 'Other Evaluations';

export const EvalEstMainView: FC = () => {
  const { userHasPermission } = useRbac();

  return (
    <>
      {userHasPermission('myBlocks/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title={myBocksTableTitle}
            blockQuery={useGetBlocksAssignedToFieldRepQuery}
            psfKey={PSFKeys.MyEvals}
          />
          <Divider />
        </>
      )}
      {userHasPermission('allEvals/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title={allEvalTableTitle}
            blockQuery={useGetAllOrOtherBlocksWithEvaluationQuery}
            psfKey={PSFKeys.AllEvals}
          />
          <Divider />
        </>
      )}
      {userHasPermission('blankEvals/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title={blankEvalTableTitle}
            blockQuery={useGetBlocksPendingEvaluationQuery}
            psfKey={PSFKeys.BlankEvals}
          />
          <Divider />
        </>
      )}
      {userHasPermission('otherEvals/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title={otherEvalTableTitle}
            blockQuery={useGetAllOrOtherBlocksWithEvaluationQuery}
            psfKey={PSFKeys.OtherEvals}
          />
          <Divider />
        </>
      )}
      <VarietalSumTable title='Varietal Summary' />
    </>
  );
};
