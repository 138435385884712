import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { RootState } from 'app/redux';
import { versionSlice } from 'app/redux/versionSlice';
import { environment } from 'environment';
import * as authLocalStorage from 'features/auth/authLocalStorage';
import { authSlice } from 'features/auth/authSlice';
import { StatusCodes } from 'http-status-codes';

/**
 * An object containing header version information used in both http
 * requests and responses.
 *
 * @param name The string name of the header.
 * @param noVersionValue The string value to expect when the client was
 * unable to obtain the server version before making its request.
 *
 * @remarks On first render, the client can issue requests without knowing the
 * server version yet. For that scenario, we pass along a string value the
 * server can expect so that the server does not conclude a version mismatch.
 */
export const versionHeader = Object.freeze({
  name: 'server-version',
  noVersionValue: 'null',
});

const baseQuery = fetchBaseQuery({
  baseUrl: environment.apiRoute,
  prepareHeaders: (headers: Headers, { getState }) => {
    const state = getState() as RootState;
    const { firstLoadVersion } = state.versionSlice;
    const { token } = state.auth;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    headers.set(
      versionHeader.name,
      firstLoadVersion ?? versionHeader.noVersionValue,
    );

    return headers;
  },
});

export const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  const responseVersion = result.meta?.response?.headers?.get(
    versionHeader.name,
  );

  if (responseVersion) {
    // Check to see if the refresh button should be shown.
    api.dispatch(versionSlice.actions.compareVersions(responseVersion));
  }

  if (result.error && result.error.status === StatusCodes.UNAUTHORIZED) {
    api.dispatch(authSlice.actions.userLoggedOut());
    authLocalStorage.clearAuthState();
  }

  return result;
};
