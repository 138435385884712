import { useAppSelector } from 'app/redux';
import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { useGetBlocksToScheduleQuery } from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { DataTable, DataTableProps } from 'common/components/DataTable';
import { usePSFQuery } from 'common/hooks';
import { PaginatedResult } from 'common/models';
import { DateWithoutTime } from 'common/models/dateType';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import { growerIdFilter } from 'utils/tableFilters/growerFilters';
import {
  AvailBlocksTableItem,
  useAvailBlocksTableData,
} from '../hooks/useAvailBlocksTableData';

export const AvailableBlocksTable: FC<{
  varietyId: number;
  pickDay: DateWithoutTime;
  packHouseId: number;
  setBlocks: Dispatch<SetStateAction<BlockDetails<WithAllSeasonData>[]>>;
}> = ({ varietyId, pickDay, packHouseId, setBlocks }) => {
  const { query, setQuery } = usePSFState(PSFKeys.SchedulableBlocks);
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const {
    data,
    isLoading,
    isFetching,
    pagination,
    filters,
    applyFilters,
    error: getBlocksError,
  } = usePSFQuery<PaginatedResult<BlockDetails<WithAllSeasonData>>>(
    useGetBlocksToScheduleQuery,
    {},
    { ...query },
    setQuery,
    { seasonId: selectedSeason?.id, varietyId, pickDay, packHouseId },
  );
  const blocks = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useAvailBlocksTableData(
    isLoading || isFetching,
    setBlocks,
    blocks,
  );

  const tableProps: DataTableProps<AvailBlocksTableItem> = {
    unitToPaginate: 'blocks',
    isLoading,
    isFetching,
    columns,
    data: tableData,
    pagination,
    filterBy: {
      filterParams: [growerIdFilter],
      selectedFilters: filters,
      applyFilters,
    },
    loadError: !!getBlocksError,
  };

  useEffect(() => {
    if (getBlocksError)
      handleError(getBlocksError, 'Unable to load blocks to schedule.');
  }, [getBlocksError]);

  return <DataTable<AvailBlocksTableItem> {...tableProps} />;
};
