import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { SavedFilterOptions, SavedSortOptions } from 'common/hooks';
import {
  GrowerBlock,
  PaginatedResult,
  PaginationQueryParams,
} from 'common/models';
import { DateWithoutTime } from 'common/models/dateType';
import { HarvestEstimate } from 'common/models/growerBlock/harvestEstimate';
import {
  BlockDetails,
  WithAllSeasonData,
  WithPartialSeasonData,
} from './dto/get-block.dto';
import {
  BlockEvalData,
  UpdateBlockStatusDto,
  UpdateDetailsDto,
  UpdateEvalDto,
  UpdateLatestEstimateDto,
  UpdateMonitoringDto,
  UpdateSizesDto,
} from './dto/growerBlockApiTypes';
import { withEmbeddedSortsAndFilters, withFilters } from './utils/apiFunctions';

const blocksBaseUrl = '/grower-blocks';
const withEvaluationPath = 'evaluation';

const allBlocks = 'AllBlocks';
const allOrOtherEvalTag = 'AllOrOtherEval';
const allPendingEval = 'AllPendingEval';
const toScheduleTag = 'ToSchedule';
const blockTag = 'Block';
const evaluationTag = 'Evaluation';
const estimateTag = 'Estimate';

type BlockSortFilterQueryParams = {
  sorts: SavedSortOptions[];
  filters: SavedFilterOptions[];
};

export const growerBlockApi = createApi({
  reducerPath: 'growerBlockApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: [
    allBlocks,
    allOrOtherEvalTag,
    allPendingEval,
    toScheduleTag,
    blockTag,
    evaluationTag,
    estimateTag,
  ],

  endpoints: builder => ({
    getAllBlocks: builder.query<
      PaginatedResult<BlockDetails<WithPartialSeasonData>>,
      PaginationQueryParams & BlockSortFilterQueryParams & { seasonId: number }
    >({
      query: ({ seasonId, ...prm }) =>
        withFilters(prm, `${blocksBaseUrl}/list/${seasonId}`),
      providesTags: (_, err, args) =>
        err ? [] : [{ type: allBlocks, id: args.seasonId }],
    }),

    getAllOrOtherBlocksWithEvaluation: builder.query<
      PaginatedResult<BlockDetails<WithAllSeasonData>>,
      PaginationQueryParams & BlockSortFilterQueryParams & { seasonId: number }
    >({
      query: ({ seasonId, ...prm }) =>
        withEmbeddedSortsAndFilters(
          prm,
          `${blocksBaseUrl}/${withEvaluationPath}/list/${seasonId}`,
        ),
      providesTags: (_, err, args) =>
        err ? [] : [{ type: allOrOtherEvalTag, id: args.seasonId }],
    }),

    getBlocksAssignedToFieldRep: builder.query<
      PaginatedResult<BlockDetails<WithAllSeasonData>>,
      PaginationQueryParams & BlockSortFilterQueryParams & { seasonId: number }
    >({
      query: ({ seasonId, ...prm }) =>
        withEmbeddedSortsAndFilters(
          prm,
          `${blocksBaseUrl}/${withEvaluationPath}/list/assigned/${seasonId}`,
        ),
      providesTags: (_, err, args) =>
        err ? [] : [{ type: allOrOtherEvalTag, id: args.seasonId }],
    }),

    getBlocksPendingEvaluation: builder.query<
      PaginatedResult<BlockDetails<WithAllSeasonData>>,
      PaginationQueryParams & BlockSortFilterQueryParams & { seasonId: number }
    >({
      query: ({ seasonId, ...prm }) =>
        withEmbeddedSortsAndFilters(
          prm,
          `${blocksBaseUrl}/${withEvaluationPath}/list/pending/${seasonId}`,
        ),
      providesTags: (_, err, args) =>
        err ? [] : [{ type: allPendingEval, id: args.seasonId }],
    }),

    getBlocksToSchedule: builder.query<
      PaginatedResult<BlockDetails<WithAllSeasonData>>,
      PaginationQueryParams &
        BlockSortFilterQueryParams & {
          seasonId: number;
          varietyId: number;
          pickDay: DateWithoutTime;
          packHouseId: number;
        }
    >({
      query: ({ seasonId, varietyId, pickDay, packHouseId, ...psfParams }) =>
        withFilters(
          psfParams,
          `${blocksBaseUrl}/schedule/${seasonId}/${varietyId}/${pickDay}/${packHouseId}`,
        ),
      providesTags: (_, err, { seasonId, varietyId, pickDay, packHouseId }) =>
        err
          ? []
          : [
              {
                type: toScheduleTag,
                id: `${seasonId}/${varietyId}/${pickDay}/${packHouseId}`,
              },
            ],
    }),

    getBlockWithBaseDetails: builder.query<
      BlockDetails<WithPartialSeasonData>,
      { blockId: number; seasonId: number }
    >({
      query: ({ seasonId, blockId }) =>
        `${blocksBaseUrl}/${seasonId}/${blockId}`,
      providesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              {
                type: blockTag,
                id: `${seasonId}/${blockId}`,
              },
            ],
    }),

    getBlockWithAllDetails: builder.query<
      BlockDetails<WithAllSeasonData>,
      { id: number; seasonId: number }
    >({
      query: ({ seasonId, id }) =>
        `${blocksBaseUrl}/${withEvaluationPath}/${seasonId}/${id}`,
      providesTags: (_, err, { seasonId, id }) =>
        err
          ? []
          : [
              {
                type: blockTag,
                id: `${withEvaluationPath}/${seasonId}/${id}`,
              },
            ],
    }),

    getBlockSeasonEvalData: builder.query<
      BlockEvalData[],
      { seasonId: number }
    >({
      query: ({ seasonId }) => ({
        url: `${blocksBaseUrl}/evaluation-data/${seasonId}`,
        method: 'GET',
      }),
      forceRefetch: () => true,
    }),

    addToSeason: builder.mutation<
      WithAllSeasonData,
      { seasonId: number; blockId: number }
    >({
      query: ({ seasonId, blockId }) => ({
        url: `${blocksBaseUrl}/season/${seasonId}/${blockId}`,
        method: 'POST',
      }),
      invalidatesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              { type: allBlocks, id: seasonId },
              { type: blockTag, id: `${seasonId}/${blockId}` },
            ],
    }),

    updateBlockStatus: builder.mutation<
      WithAllSeasonData,
      UpdateBlockStatusDto & { seasonId: number; blockId: number }
    >({
      query: ({ seasonId, blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/status/${seasonId}/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              allBlocks,
              allOrOtherEvalTag,
              allPendingEval,
              toScheduleTag,
              {
                type: blockTag,
                id: `${seasonId}/${blockId}`,
              },
            ],
    }),

    updateBlock: builder.mutation<
      WithAllSeasonData,
      UpdateDetailsDto & { seasonId: number; blockId: number }
    >({
      query: ({ seasonId, blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/${seasonId}/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      // Refetch all blocks as well as the block with the updated details.
      invalidatesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              allBlocks,
              allOrOtherEvalTag,
              allPendingEval,
              toScheduleTag,
              {
                type: blockTag,
                id: `${seasonId}/${blockId}`,
              },
              {
                type: blockTag,
                id: `${withEvaluationPath}/${seasonId}/${blockId}`,
              },
            ],
    }),

    updateEvaluation: builder.mutation<
      WithAllSeasonData,
      UpdateEvalDto & { seasonId: number; blockId: number }
    >({
      query: ({ seasonId, blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/${withEvaluationPath}/${seasonId}/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      // Refetch all blocks as well as the block with the updated evaluation.
      invalidatesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              allBlocks,
              allOrOtherEvalTag,
              allPendingEval,
              toScheduleTag,
              {
                type: blockTag,
                id: `${seasonId}/${blockId}`,
              },
              {
                type: blockTag,
                id: `${withEvaluationPath}/${seasonId}/${blockId}`,
              },
            ],
    }),

    updateSizes: builder.mutation<
      GrowerBlock,
      UpdateSizesDto & { seasonId: number; blockId: number }
    >({
      query: ({ seasonId, blockId, sizeEstimates }) => ({
        url: `${blocksBaseUrl}/size-estimates/${seasonId}/${blockId}`,
        method: 'PUT',
        body: { sizeEstimates },
      }),
      // Refetch the block with the updated sizes.
      invalidatesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              allBlocks,
              allOrOtherEvalTag,
              allPendingEval,
              toScheduleTag,
              {
                type: blockTag,
                id: `${withEvaluationPath}/${seasonId}/${blockId}`,
              },
            ],
    }),

    updateLatestEstimate: builder.mutation<
      HarvestEstimate,
      UpdateLatestEstimateDto & { seasonId: number; blockId: number }
    >({
      query: ({ seasonId, blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/latest-estimate/${seasonId}/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              allBlocks,
              allOrOtherEvalTag,
              allPendingEval,
              toScheduleTag,
              {
                type: blockTag,
                id: `${withEvaluationPath}/${seasonId}/${blockId}`,
              },
            ],
    }),

    updateMonitoring: builder.mutation<
      WithAllSeasonData,
      UpdateMonitoringDto & { seasonId: number; blockId: number }
    >({
      query: ({ seasonId, blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/monitoring/${seasonId}/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      // Refetch the block with the updated orchard monitoring.
      invalidatesTags: (_, err, { seasonId, blockId }) =>
        err
          ? []
          : [
              {
                type: blockTag,
                id: `${seasonId}/${blockId}`,
              },
              {
                type: blockTag,
                id: `${withEvaluationPath}/${seasonId}/${blockId}`,
              },
            ],
    }),
  }),
});

export const {
  useGetAllBlocksQuery,
  useGetBlocksAssignedToFieldRepQuery,
  useGetAllOrOtherBlocksWithEvaluationQuery,
  useGetBlocksPendingEvaluationQuery,
  useGetBlocksToScheduleQuery,
  useGetBlockSeasonEvalDataQuery,
  useGetBlockWithBaseDetailsQuery,
  useGetBlockWithAllDetailsQuery,
  useAddToSeasonMutation,
  useUpdateBlockStatusMutation,
  useUpdateBlockMutation,
  useUpdateEvaluationMutation,
  useUpdateLatestEstimateMutation,
  useUpdateSizesMutation,
  useUpdateMonitoringMutation,
} = growerBlockApi;
