import { handleError } from 'common/api/handleError';
import { useGetUsersQuery } from 'common/api/userApi';
import {
  DataTable,
  DataTableMobile,
  DataTableProps,
} from 'common/components/DataTable';
import { usePSFQuery } from 'common/hooks';
import useWindowSize from 'common/hooks/useWindowSize';
import { PaginatedResult, User } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import { userTableFilters } from 'features/user-views/filters';
import { FC, useEffect, useMemo } from 'react';
import { Constants } from 'utils/constants';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import { UserTableItem, useUserTableData } from '../hooks/useUserTableData';
import { useUserTableDataMobile } from '../hooks/useUserTableDataMobile';

export const UserListView: FC = () => {
  const { query, setQuery } = usePSFState(PSFKeys.Users);

  const {
    data,
    isLoading,
    isFetching,
    pagination,
    applyFilters,
    filters,
    error: getUsersError,
  } = usePSFQuery<PaginatedResult<User>>(
    useGetUsersQuery,
    {},
    {
      ...query,
    },
    setQuery,
  );

  const users = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useUserTableData(users);
  const columnsMobile = useUserTableDataMobile();

  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);

  const buildDetailsLink = (id: number) =>
    `${Constants.routes.USER_DETAILS}${id}?${Constants.paginationParams.FROM_PAGE}=${pagination.page}`;

  const tableProps: DataTableProps<UserTableItem> = {
    title: 'Users',
    unitToPaginate: 'users',
    isLoading,
    isFetching,
    columns: isMobile ? columnsMobile : columns,
    data: tableData,
    pagination,
    onRowClick: user => buildDetailsLink(user.id),
    filterBy: {
      filterParams: userTableFilters,
      selectedFilters: filters,
      applyFilters,
    },
    loadError: !!getUsersError,
  };

  useEffect(() => {
    if (getUsersError) {
      handleError(getUsersError, 'Unable to load users');
    }
    window.history.pushState(
      {},
      '',
      `${Constants.routes.USERS_LIST}?${Constants.paginationParams.PAGE}=${pagination.page}`,
    );
  }, [getUsersError, pagination.page]);

  return (
    <>
      {isMobile ? (
        <DataTableMobile<UserTableItem> {...tableProps} />
      ) : (
        <DataTable<UserTableItem> {...tableProps} />
      )}
    </>
  );
};
