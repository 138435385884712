import {
  SyncNotification,
  SyncTaskNotification,
} from 'common/models/databaseSync/syncNotification';
import { DatabaseSyncStatus } from 'common/models/databaseSync/syncStatus';
import { SyncTaskStatus } from 'common/models/databaseSync/syncTaskStatus';
import { taskNames } from './taskNames';

function feedbackMessage(notification: SyncNotification): string {
  let message: string;
  const { status, task } = notification;

  switch (status) {
    case DatabaseSyncStatus.Pending:
      message = 'Sync in progress...';
      break;
    case DatabaseSyncStatus.Complete:
      message = 'Sync completed.';
      break;
    case DatabaseSyncStatus.PartiallyComplete:
      message = 'Sync partially completed.';
      break;
    case DatabaseSyncStatus.Failed:
      message = 'Sync failed, please try again.';
      break;
    case DatabaseSyncStatus.None:
      message = '';
      break;
    default:
      message = `(Unknown sync status: ${status})`;
      break;
  }

  if (task) {
    const taskName = taskNames[task.descriptor];

    switch (task.status) {
      case SyncTaskStatus.Started:
        message = `Synchronizing ${taskName}...`;
        break;
      case SyncTaskStatus.Complete:
        message = `Completed synchronizing ${taskName}.`;
        break;
      case SyncTaskStatus.Failed:
        message = `Failed to synchronize ${taskName}.`;
        break;
      case SyncTaskStatus.Skipped:
        message = `Skipping synchronization of ${taskName}.`;
        break;
      case SyncTaskStatus.None:
        message = '';
        break;
      default:
        message = `(Unknown sync status ${task.status} for ${taskName}).`;
        break;
    }
  }

  return message;
}

function isSyncStart(notification: SyncNotification): boolean {
  return (
    notification.status === DatabaseSyncStatus.Pending && !notification.task
  );
}

function isCompletion(notification: SyncTaskNotification): boolean {
  return (
    notification.status === SyncTaskStatus.Complete ||
    notification.status === SyncTaskStatus.Failed ||
    notification.status === SyncTaskStatus.Skipped
  );
}

function mergePrevious(
  localTasks: SyncTaskNotification[],
  serverTasks: SyncTaskNotification[] | undefined,
): SyncTaskNotification[] {
  const serverPrevious = serverTasks?.filter(
    srv =>
      isCompletion(srv) &&
      !localTasks.find(loc => loc.descriptor === srv.descriptor),
  );

  return (serverPrevious ?? []).concat(localTasks);
}

function updateCurrentTasks(
  previous: SyncTaskNotification[],
  current: SyncNotification,
): SyncTaskNotification[] {
  const notifications = mergePrevious(previous, current.previousTasks);

  if (current?.task && isCompletion(current.task)) {
    notifications.push(current.task);
  }

  return notifications;
}

export { feedbackMessage, isSyncStart, updateCurrentTasks };
