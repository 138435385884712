import * as Sentry from '@sentry/react';
import { store } from 'app/redux';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { sentryErrorHandledProperty } from 'common/api/handleError';
import { environment } from 'environment';
import { Constants } from 'utils/constants';
import { SentryReduxState } from 'app/redux/sentryReduxSetup';

Sentry.init({
  dsn: 'https://eb5d615d9b3e3d5a1262b21844d2fcd7@o4506621406412800.ingest.sentry.io/4506625051000832',
  environment: environment.name,
  release: Constants.release,
  autoSessionTracking: false,
  // Replace default (3) to see redux state in Sentry UI.
  normalizeDepth: 5,
  beforeSend(event) {
    if (process.env.NODE_ENV === 'production') {
      // Determine if our error handler dealt with this
      const handled =
        event.contexts && event.contexts[sentryErrorHandledProperty];

      if (!handled && event.event_id) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      const reduxState = event.contexts?.state?.state?.value;
      const userState = reduxState && (reduxState as SentryReduxState).user;

      // We log the user on the event in addition to redux state so that
      // Sentry can track the user in its UI context.
      event.user = userState || undefined;

      return event;
    }
    return null;
  },
});

ReactDOM.render(
  <StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
