import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { SavedFilterOptions, SavedSortOptions } from 'common/hooks';

export const FilterValueSeparator = '|';

interface SortParams {
  sorts: SavedSortOptions[];
}

interface FilterParams {
  filters: SavedFilterOptions[];
}

export type SortFilterParams = SortParams & FilterParams;

/**
 * Builds the arguments object for a query, ensuring that filters are serialized
 * in the format expected by the backend.
 *
 * @param params The object whose properties will be used as query parameters,
 *  including, at a minimum, the filters to process.
 * @param baseUrl The base URL to which parameters will be appended.
 */
export function withFilters<T extends SortFilterParams>(
  {
    sorts, // Destructure to avoid passing sorts with remaining params.
    filters,
    ...otherParams
  }: T,
  baseUrl: string,
): FetchArgs {
  // Serialize and encode filters. We can't use built-in encoding on this whole
  // string because the server expects separators (such as the colon) literally.
  const serializedFilters = filters
    .map(filter => {
      const encodedOptions = filter.options.map(opt =>
        encodeURIComponent(opt.trim()),
      );
      return `${filter.category}:${encodedOptions.join(FilterValueSeparator)}`;
    })
    .join(',');

  const filtersParam = serializedFilters ? `?filter=${serializedFilters}` : '';

  // Append filters manually because we don't want them processed any further
  // (see above). Passing other params straight thru lets built-in encoding apply.
  return {
    url: `${baseUrl}${filtersParam}`,
    params: otherParams,
  };
}

/**
 * Builds the arguments object for a query, ensuring that _embedded_ sorts and
 * filters are serialized in the format expected by the backend.
 *
 * @param params The object whose properties will be used as query parameters,
 *  including, at a minimum, the sorts and filters to process.
 * @param baseUrl The base URL to which parameters will be appended.
 */
export function withEmbeddedSortsAndFilters<T extends SortFilterParams>(
  { sorts, filters, ...otherParams }: T,
  baseUrl: string,
): FetchArgs {
  const serializedSorts = sorts
    .map(sort => {
      const encodedKeys = sort.options.map(
        /*
        Because the server-side PSF package expects a limited sort type,
        we are using a (hacky) work-around; we pass the sort's 'category'
        option on the key ({ '[category].[option]': 'DESC' }).
        */
        opt => `${sort.category}.${encodeURIComponent(opt.trim())}`,
      );

      return encodedKeys.join();
    })
    .join(',');

  const serializedFilters = filters
    .map(filter => {
      const encodedOptions = filter.options.map(opt =>
        encodeURIComponent(opt.trim()),
      );
      return `${filter.category}:${encodedOptions.join(FilterValueSeparator)}`;
    })
    .join(',');

  let queryString = '';

  if (serializedSorts && !serializedFilters) {
    queryString = `?sort=${serializedSorts}`;
  }

  if (!serializedSorts && serializedFilters) {
    queryString = `?filter=${serializedFilters}`;
  }

  if (serializedSorts && serializedFilters) {
    queryString = `?sort=${serializedSorts}&filter=${serializedFilters}`;
  }

  return {
    url: `${baseUrl}${queryString}`,
    params: otherParams,
  };
}
