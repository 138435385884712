import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ReactElement, useState } from 'react';
import { Option, TableSortFilterParams } from '../DataTable';
import { SortFilterCheckbox } from './SortFilterCheckbox';
import { AppliedSortFilter } from './SortFilterHandler';
import {
  Category,
  Row,
  RowContainer,
  ScrollableContainer,
  SortFilterCheckboxContainer,
  SubHeader,
  TextInput,
} from './styles';

interface CategoryProps {
  category: TableSortFilterParams;
  appliedOptions: AppliedSortFilter[];
  addCheckboxValue: (
    value: string,
    category: string,
    singleSelect: boolean,
  ) => void;
  addInputValue?: (value: string, category: string) => void;
  removeValue: (value: string) => void;
  subHeader?: string;
}

export const SortFilterCategory = ({
  category,
  appliedOptions,
  addCheckboxValue,
  addInputValue,
  removeValue,
  subHeader,
}: CategoryProps): ReactElement => {
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const [textInputValue, setTextInputValue] = useState('');
  const showCheckBox: boolean =
    optionsExpanded && !!category?.options && category?.options?.length !== 0;
  const showInput: boolean =
    optionsExpanded && !!category?.options && category?.options?.length === 0;
  const showErrMsg: boolean = optionsExpanded && !category;
  const includeSeparator = (
    options: Option[] | Option[][],
  ): options is Option[][] => Array.isArray(options[0]);

  const options = category.options ?? [];

  const renderOptions = (option: Option, separator = false) => (
    <SortFilterCheckboxContainer key={option.value}>
      <SortFilterCheckbox
        label={option.label}
        value={option.value}
        category={category.category.key}
        isChecked={
          !!appliedOptions.find(
            selection =>
              selection.value === option.value &&
              category.category.key === selection.category,
          )
        }
        addCheckboxValue={addCheckboxValue}
        removeValue={removeValue}
        separator={separator}
        singleSelect={category.singleSelect}
      />
    </SortFilterCheckboxContainer>
  );

  const optionsElements = includeSeparator(options)
    ? options
        .filter(group => group.length > 0)
        .map((group, i, groups) =>
          group.map((option, j) => {
            // Only display separator between groups.
            const renderSeparator =
              j + 1 === group.length && i + 1 !== groups.length;
            return renderOptions(option, renderSeparator);
          }),
        )
        .flat()
    : options.map(option => renderOptions(option));

  return (
    <RowContainer>
      <Row type='button' onClick={() => setOptionsExpanded(!optionsExpanded)}>
        <Category>{category.category.label}</Category>
        {optionsExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Row>
      {subHeader && (showCheckBox || showInput) && (
        <SubHeader>{subHeader}</SubHeader>
      )}
      {showCheckBox && (
        <ScrollableContainer>{optionsElements}</ScrollableContainer>
      )}
      {showInput && addInputValue && (
        <TextInput
          type='text'
          value={
            appliedOptions.find(
              input => input.category === category.category.key,
            )
              ? textInputValue
              : ''
          }
          onChange={event =>
            setTextInputValue((event.target as HTMLInputElement).value)
          }
          onInput={event =>
            addInputValue(
              (event.target as HTMLInputElement).value,
              category.category.key,
            )
          }
        />
      )}
      {showErrMsg && <p>There was an issue showing options.</p>}
    </RowContainer>
  );
};
