import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { defaultPaginationConfig } from 'common/hooks';
import { FC, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import { DataTablePaginationProps } from '../DataTable';
import { PageSizeInput, StyledContainer, StyledSpan } from './styles';

/**
 * Creates the array of numbers for the paginator to display to the user.
 *
 * @param currentPage The page that the user is currently on.
 * @param pageCount The total number of pages available.
 * @param linkCount The max amount of page numbers to show.
 */
function createPageNumArray(
  currentPage: number,
  pageCount: number,
  linkCount = 3,
): number[] {
  const arr: number[] = [];
  if (linkCount >= pageCount) {
    for (let i = 0; i < pageCount; i += 1) {
      arr.push(i + 1);
    }
    return arr;
  }

  const range = Math.floor(linkCount / 2);
  const firstLink = currentPage - range;
  const lastLink = currentPage + (linkCount - range - 1);
  let offset = 0;

  if (firstLink < 1) {
    offset = 1 + Math.abs(firstLink);
  } else if (lastLink > pageCount) {
    offset = pageCount - lastLink;
  }

  for (let i = 0; i < linkCount; i += 1) {
    arr.push(offset + firstLink + i);
  }

  return arr;
}

type Props = DataTablePaginationProps & {
  unitToPaginate?: string;
  isFetching: boolean;
};

export const Paginator: FC<Props> = ({
  unitToPaginate,
  page,
  pageSize,
  count,
  pageCount,
  getPage,
  getNextPage,
  getPreviousPage,
  changePageSize,
  hasNextPage,
  hasPreviousPage,
  isFetching,
}) => {
  const inputId = 'page-size-input';
  const pageNumArr = useMemo(
    () => createPageNumArray(page, pageCount),
    [page, pageCount],
  );

  const handlePageChangeBlur = (page: number) => {
    if (
      Number.isInteger(page) &&
      page >= defaultPaginationConfig.minPageSize &&
      page <= defaultPaginationConfig.maxPageSize
    ) {
      changePageSize(page);
    } else {
      const inputElement = document.getElementById(inputId) as HTMLInputElement;

      inputElement.value = pageSize.toString();
    }
  };

  return (
    <div className='d-flex flex-column mt-2'>
      <StyledSpan>
        Showing up to
        <PageSizeInput
          type='number'
          id={inputId}
          defaultValue={pageSize}
          onBlur={e => handlePageChangeBlur(Number(e.target.value))}
        />
        {unitToPaginate}, {count} total.
      </StyledSpan>
      <StyledContainer className='d-flex align-items-center'>
        <Button
          variant='link'
          disabled={!hasPreviousPage || isFetching}
          onClick={() => getPreviousPage()}
        >
          <ChevronLeftIcon />
        </Button>
        <p style={{ height: '100%' }}>{page - 3 > 1 ? '...' : ''}</p>
        {pageNumArr.map(num => (
          <button
            type='button'
            disabled={isFetching}
            tabIndex={num}
            key={num}
            className='d-flex flex-column m-1'
            onClick={() => getPage(num)}
            style={{ border: 'none', background: 'inherit' }}
          >
            <p className={num === page ? 'viewing-page' : ''}>{num}</p>
            <div
              className={num === page ? 'viewing-page-underscore' : ''}
              style={{ width: '100%', height: 3 }}
            />
          </button>
        ))}
        <p style={{ height: '100%' }}>{page + 3 < pageCount ? '...' : ''}</p>
        <Button
          variant='link'
          disabled={!hasNextPage || isFetching}
          onClick={() => getNextPage()}
        >
          <ChevronRightIcon />
        </Button>
      </StyledContainer>
    </div>
  );
};
