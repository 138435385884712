import * as Sentry from '@sentry/react';
import { RoleType, User } from 'common/models';
import { Season } from 'common/models/season';
import { authSliceName, AuthState } from 'features/auth/authSlice';
import {
  ConfirmationModalState,
  modalSliceName,
} from 'features/confirmation-modal/slice';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';

type SentryUserDto = Pick<User, 'id' | 'email' | 'sessionStarted'> & {
  role: RoleType;
  name: string;
};

export type SentryReduxState = {
  user: SentryUserDto | null;
  season: Partial<Season> | null;
  modal: Partial<ConfirmationModalState>;
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state): SentryReduxState => {
    let loggedInUser: SentryUserDto | null = null;
    let season: Partial<Season> | null = null;

    const { user } = state[authSliceName] as AuthState;
    const { selectedSeason } = state[seasonSliceName] as SeasonState;
    const { show, loading, title, body } = state[
      modalSliceName
    ] as ConfirmationModalState;

    if (user?.id) {
      const { id, firstName, lastName, email, role, sessionStarted } = user;

      loggedInUser = {
        id,
        email,
        name: `${firstName} ${lastName}`,
        role: role?.roleName,
        sessionStarted,
      };
    }

    if (selectedSeason?.id) {
      const { id, year } = selectedSeason;

      season = {
        id,
        year,
      };
    }

    return {
      user: loggedInUser,
      season,
      modal: {
        show,
        loading,
        title,
        body,
      },
    };
  },
});
