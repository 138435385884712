import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Column, Row } from 'react-table';
import { RowBuilder } from '../DataTable';
import { expandableRowKey } from '../TableBody/TableBody';

/**
 * A hook to create a table {@link Column} object with multiple
 * chevrons linked by a common state. The state is managed through the
 * `useExpanded` hook from `react-table`.
 *
 * @param width A string representing the width of the column.
 */
export function useChevronColumn<T extends { buildExpandedRow: RowBuilder }>(
  width: string,
): Column<T> {
  return {
    accessor: expandableRowKey as keyof T,
    width,
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <button
        type='button'
        {...getToggleAllRowsExpandedProps()}
        style={{ border: 'none', background: 'none' }}
      >
        {isAllRowsExpanded ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </button>
    ),
    Cell: ({ row }: { row: Row<T> }) => (
      <button
        type='button'
        {...row.getToggleRowExpandedProps()}
        style={{ border: 'none', background: 'none' }}
      >
        {row.isExpanded ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </button>
    ),
  };
}
