import { ErrorOutline, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  LinearProgress,
} from '@mui/material';
import { DisabledButtonColors } from 'common/styles/button';
import { darkNavy, red, white } from 'common/styles/colors';
import styled from 'styled-components';

type StatusLabelProps = { isError: boolean };

export const SpokaneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const StatusLabel = styled.p<StatusLabelProps>`
  color: ${props => (props.isError ? red : darkNavy)};
  margin: 0;
`;

export const SyncButton = styled.button`
  background-color: ${darkNavy};
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: ${white};
  border: none;
  padding: 8px;
  font-family: KanitLight;

  &:disabled {
    cursor: wait;
    ${DisabledButtonColors}
  }
`;

export const SyncProgressBar = styled(LinearProgress)`
  width: 100%;
`;

export const ErrorAccordion = styled(Accordion).attrs(
  () =>
    ({
      square: true,
      style: { backgroundColor: 'inherit' },
    }) as AccordionProps,
)`
  width: 100%;
`;

export const ErrorIcon = styled(ErrorOutline)`
  margin-right: 3px;
`;

export const AccordionHeader = styled(AccordionSummary).attrs(
  () =>
    ({
      expandIcon: <ExpandMore />,
    }) as AccordionSummaryProps,
)``;

export const ErrorTableStyledWrapper = styled.div`
  & > div {
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    & > div.MuiButtonBase-root {
      padding: 0;
    }

    .MuiAccordionDetails-root {
      padding: 0;

      & > div {
        box-shadow: none;
        padding: 0;
      }
    }
    p {
      color: ${red};
      margin-bottom: 0;
    }
  }
`;
