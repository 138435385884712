import { Option } from 'common/components/DataTable';
import { Area, SubVariety, Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { sortSizes } from 'utils/formValues/sizeEstimates';

/**
 * Search operations for sorting.
 * These string must match the strings server-side.
 * */
export enum OrderOps {
  ASC = '',
  DESC = '-',
}

/**
 * Search operations for filtering.
 * These string must match the strings server-side.
 * */
export enum SearchOps {
  EQUALS = '__eq',
  ICONTAINS = '__icontains',
}

/** Returns the list of variety filters.
 *
 * @remark - The odd return type is due to the fact that the filters are split
 * by organic and non-organic varieties. The two separate arrays allows for
 * a line break style to be added between the two groups in the dropdown.
 * */
export const createVarietyFilters = (varieties: Variety[]): Option[][] => {
  const nonOrganic: Option[] = [];
  const organic: Option[] = [];
  const nonSorted = [...varieties];
  const orgStr = 'Organic';

  const sortedVarieties = nonSorted.sort((a, b) =>
    a.varietyName.localeCompare(b.varietyName),
  );

  sortedVarieties.forEach(variety => {
    const filter = {
      label: `${variety.varietyCode} - ${variety.varietyName}${
        variety.category === orgStr ? ` ${orgStr}` : ''
      }`,
      value: variety.varietyCode,
    };

    (filter.label.includes(orgStr) ? organic : nonOrganic).push(filter);
  });

  return [nonOrganic, organic];
};

export const createSizesSort = (sizes: Size[]): Option[] =>
  sortSizes(sizes).map(size => ({
    label: size.value,
    value: size.id.toString(),
  }));

export const createSubVarietyFilters = (subVarieties: SubVariety[]): Option[] =>
  subVarieties
    .map(subVar => ({
      label: subVar.subVarietyCode,
      value: subVar.id.toString(),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const createAreaFilters = (areas: Area[]): Option[] =>
  areas
    .map(area => ({
      label: area.areaName,
      value: area.id.toString(),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
