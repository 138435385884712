import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { SavedFilterOptions, SavedSortOptions } from 'common/hooks';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import { FC, useState } from 'react';
import { TableSortFilterParams } from '../DataTable';
import { SortFilterBubble } from './SortFilterBubble';
import { SortFilterCategory } from './SortFilterCategory';
import {
  AddSortFilterBtn,
  ApplyBtn,
  BubblesContainer,
  Header,
  SortFilterModal,
  SortFilterWrapper,
  Title,
} from './styles';

export type TableFilterProps = {
  filterParams: TableSortFilterParams[];
  /**
   * On first render, these are the preselected filters and on subsequent renders,
   * this is whatever is currently selected.
   */
  selectedFilters: SavedFilterOptions[];
  applyFilters: (filters: SavedFilterOptions[]) => void;
};

export type TableSortProps = {
  sortParams: TableSortFilterParams[];
  /**
   * On first render, these are the preselected sorts and on subsequent renders,
   * this is whatever is currently selected.
   */
  selectedSorts: SavedSortOptions[];
  applySorts: (sorts: SavedSortOptions[]) => void;
};

export type AppliedSortFilter = {
  value: string;
  category: string;
};

function toSortsOrFilters(
  sortsOrFilters?: (SavedSortOptions | SavedFilterOptions)[],
): AppliedSortFilter[] {
  return (sortsOrFilters ?? []).reduce(
    (all, current) =>
      all.concat(
        current.options.map(option => ({
          value: option,
          category: current.category,
        })),
      ),
    [] as AppliedSortFilter[],
  );
}

function toCategoryOptions(
  sortOrFilters: AppliedSortFilter[],
): (SavedSortOptions | SavedFilterOptions)[] {
  const savedOptions = [] as (SavedSortOptions | SavedFilterOptions)[];

  sortOrFilters.forEach(element => {
    const index = savedOptions.findIndex(
      option => option.category === element.category,
    );

    if (index > -1) {
      savedOptions[index].options = [
        ...savedOptions[index].options,
        element.value,
      ];
    } else {
      savedOptions.push({
        options: [element.value],
        category: element.category,
      });
    }
  });
  return savedOptions;
}

/**
 * Component that handles sorting and filtering on tables. A button click
 * shows a modal with options; 'bubbles' display once a selection is applied.
 */
export const SortFilterHandler: FC<{
  sortProps?: TableSortProps;
  filterProps?: TableFilterProps;
}> = ({ sortProps, filterProps }) => {
  const { sortParams, selectedSorts, applySorts } = sortProps ?? {};
  const { filterParams, selectedFilters, applyFilters } = filterProps ?? {};

  const formattedSorts = toSortsOrFilters(selectedSorts);
  const formattedFilters = toSortsOrFilters(selectedFilters);

  const [appliedSorts, setAppliedSorts] = useState([...formattedSorts]);
  const [appliedFilters, setAppliedFilters] = useState([...formattedFilters]);
  const [showModal, setShowModal] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  /* Check to determine when text elements start to overlap. */
  const isExtraSmall = width < 375;

  const onApply = () => {
    setShowModal(false);

    if (applySorts) {
      applySorts(toCategoryOptions([...appliedSorts]));
    }

    if (applyFilters) {
      applyFilters(toCategoryOptions([...appliedFilters]));
    }
  };

  // Add or remove SORT handlers
  const addCheckboxSortValue = (
    value: string,
    category: string,
    singleSelect?: boolean,
  ) => {
    setAppliedSorts(
      singleSelect
        ? [
            ...appliedSorts.filter(sort => sort.category === category),
            { value, category },
          ]
        : [...appliedSorts, { value, category }],
    );
  };

  const removeSortValue = (value: string) => {
    let newSorts = [...appliedSorts];

    newSorts = newSorts.filter(option => option.value !== value);
    setAppliedSorts(newSorts);
    return newSorts;
  };

  const removeSortBubble = (value: string) => {
    if (applySorts) {
      applySorts(toCategoryOptions(removeSortValue(value)));
    }
  };

  // Add or remove FILTER handlers
  const addCheckboxFilterValue = (
    value: string,
    category: string,
    singleSelect?: boolean,
  ) => {
    setAppliedFilters(
      singleSelect
        ? [
            ...appliedFilters.filter(filter => filter.category !== category),
            { value, category },
          ]
        : [...appliedFilters, { value, category }],
    );
  };

  const addInputFilterValue = (value: string, category: string) => {
    let newFilters = [...appliedFilters];

    const filterIdx = appliedFilters.findIndex(
      filter => filter.category === category,
    );

    if (value && filterIdx === -1) {
      newFilters = [...newFilters, { value, category }];
    } else if (value && filterIdx > -1) {
      newFilters[filterIdx] = { value, category };
    } else {
      newFilters = newFilters.filter((_, index) => index !== filterIdx);
    }

    setAppliedFilters(newFilters);
  };

  const removeFilterValue = (value: string) => {
    let newFilters = [...appliedFilters];

    newFilters = newFilters.filter(option => option.value !== value);
    setAppliedFilters(newFilters);
    return newFilters;
  };

  const removeFilterBubble = (value: string) => {
    if (applyFilters) {
      applyFilters(toCategoryOptions(removeFilterValue(value)));
    }
  };

  return (
    <SortFilterWrapper isMobile={isMobile} isExtraSmall={isExtraSmall}>
      <BubblesContainer isMobile={isMobile} isExtraSmall={isExtraSmall}>
        {formattedSorts.length > 0 && (
          <div>
            <p className='sort-filter-prompt'>Sorts:</p>
            {formattedSorts.map(sort => {
              const appliedSort = sortParams?.find(
                param => param.category.key === sort.category,
              );
              const options = appliedSort?.options?.flat();

              return (
                <SortFilterBubble
                  key={sort.value}
                  categoryLabel={appliedSort?.category?.label ?? sort.category}
                  category={sort.category}
                  valueLabel={
                    options?.find(option => option?.value === sort.value)
                      ?.label ?? sort.value
                  }
                  value={sort.value}
                  removeBubble={removeSortBubble}
                />
              );
            })}
          </div>
        )}
        {formattedFilters.length > 0 && (
          <div>
            <p className='sort-filter-prompt'>Filters:</p>
            {formattedFilters.map(filter => {
              const appliedFilter = filterParams?.find(
                param => param.category.key === filter.category,
              );
              const options = appliedFilter?.options?.flat();

              return (
                <SortFilterBubble
                  key={filter.value}
                  categoryLabel={
                    appliedFilter?.category?.label ?? filter.category
                  }
                  category={filter.category}
                  valueLabel={
                    options?.find(option => option?.value === filter.value)
                      ?.label ?? filter.value
                  }
                  value={filter.value}
                  removeBubble={removeFilterBubble}
                />
              );
            })}
          </div>
        )}
      </BubblesContainer>
      <AddSortFilterBtn
        type='button'
        onClick={() => setShowModal(!showModal)}
        isMobile={isMobile}
        isExtraSmall={isExtraSmall}
      >
        <AddIcon className='add-icon' />
        {sortParams && !filterParams ? 'Add Sort' : ''}
        {sortParams && filterParams ? 'Sort & Filter' : ''}
        {!sortParams && filterParams ? 'Add Filter' : ''}
      </AddSortFilterBtn>
      <SortFilterModal style={{ display: showModal ? 'flex' : 'none' }}>
        <Header>
          <Title>{sortParams ? 'Sort' : 'Filter'}</Title>
          <button type='button' onClick={() => setShowModal(false)}>
            <CloseIcon />
          </button>
        </Header>
        {sortParams?.map(sort => (
          <SortFilterCategory
            key={sort.category.key}
            category={sort}
            appliedOptions={appliedSorts}
            addCheckboxValue={addCheckboxSortValue}
            removeValue={removeSortValue}
            subHeader={sort?.subHeader}
          />
        ))}
        {sortParams && filterParams && <Title>Filter</Title>}
        {filterParams?.map(filter => (
          <SortFilterCategory
            key={filter.category.key}
            category={filter}
            appliedOptions={appliedFilters}
            addCheckboxValue={addCheckboxFilterValue}
            addInputValue={addInputFilterValue}
            removeValue={removeFilterValue}
            subHeader={filter?.subHeader}
          />
        ))}
        <ApplyBtn type='button' onClick={() => onApply()}>
          APPLY
        </ApplyBtn>
      </SortFilterModal>
    </SortFilterWrapper>
  );
};
