import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { usePagination, useTable } from 'react-table';
import { Constants } from 'utils/constants';
import { getIntParam } from 'utils/paramFunctions';
import { WithLoadingOverlay } from '../LoadingSpinner';
import { DataTableProps } from './DataTable';
import { MobileTableContainer } from './DataTableStyles';
import { Paginator } from './Paginator/Paginator';
import { SortFilterHandler } from './SortFilterHandler/SortFilterHandler';
import { blueRowClass } from './TableBody/TableBody';

export const DataTableMobile = <D extends Record<string, unknown>>({
  title,
  unitToPaginate,
  isLoading,
  isFetching,
  columns,
  data,
  pagination,
  sortBy,
  filterBy,
  onRowClick,
}: DataTableProps<D>): ReactElement => {
  // Evaluate these conditions once instead of re-computing in multiple places.
  const paginationEnabled = pagination !== undefined;

  const history = useHistory();
  const currentPage = getIntParam(
    history.location.search,
    Constants.paginationParams.PAGE,
  );

  // Determine which react-table plugins should be enabled.
  const plugins = [...(paginationEnabled ? [usePagination] : [])];

  // Generate the required initial state for the enabled plugins.
  const initialState = {
    ...(paginationEnabled
      ? {
          pageIndex: (currentPage ?? 1) - 1,
          pageSize: pagination.pageSize,
        }
      : {}),
  };

  // Generate the required table options for the enabled plugins.
  const extraTableOptions = {
    ...(paginationEnabled
      ? { manualPagination: true, pageCount: pagination.pageCount }
      : {}),
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      ...extraTableOptions,
    },
    ...plugins,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
  } = tableInstance;

  // If the 'usePagination' plugin is enabled, the 'page' instance prop
  // contains the table rows for the current page.
  // Otherwise, the 'rows' instance prop contains the table rows.
  const tableRows = paginationEnabled ? page : rows;

  const hasTableData = tableRows.length > 0;

  return (
    <MobileTableContainer>
      <h3>{title}</h3>
      {(sortBy || filterBy) && (
        <SortFilterHandler sortProps={sortBy} filterProps={filterBy} />
      )}
      <WithLoadingOverlay isLoading={isLoading || isFetching}>
        {hasTableData ? (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      style={{ width: `${column.width}` }}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {tableRows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => {
                      if (onRowClick) {
                        const link = onRowClick(row.original);
                        if (typeof link === 'string') {
                          history.push(link);
                        }
                      }
                    }}
                    className={index % 2 ? blueRowClass : ''}
                    {...row.getRowProps()}
                    role='link'
                  >
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className='no-data-text'>No Data Available</p>
        )}
      </WithLoadingOverlay>
      {/* If the usePagination plugin is enabled, render a paginator
          to allow users to page through the data. */}
      {!isLoading && paginationEnabled && hasTableData && (
        <Paginator
          {...pagination}
          unitToPaginate={unitToPaginate}
          isFetching={isFetching}
        />
      )}
    </MobileTableContainer>
  );
};
