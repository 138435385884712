import { ReactElement } from 'react';
import { Checkbox, CheckboxContainer, ValueComponent } from './styles';

interface CheckBoxProp {
  label: string;
  value: string;
  category: string;
  isChecked: boolean;
  addCheckboxValue: (
    value: string,
    category: string,
    singleSelect: boolean,
  ) => void;
  removeValue: (value: string) => void;
  separator: boolean;
  singleSelect?: boolean;
}

export const SortFilterCheckbox = ({
  label,
  value,
  category,
  isChecked,
  addCheckboxValue,
  removeValue,
  separator,
  singleSelect = false,
}: CheckBoxProp): ReactElement => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addCheckboxValue(value, category, singleSelect);
    } else {
      removeValue(value);
    }
  };

  return (
    <CheckboxContainer $separator={separator}>
      <Checkbox
        id={value}
        type='checkbox'
        name={value}
        value={value}
        onChange={handleCheckboxChange}
        checked={isChecked}
      />
      <label htmlFor={value}>
        <ValueComponent className='checkboxValue'>{label}</ValueComponent>
      </label>
    </CheckboxContainer>
  );
};
